const jsonToProps = (props = {}) => {
  const { title = '', menu = [] } = props;
  return {
    title: title.text,
    tabs: menu.map(({ title: tabTitle, children = [] }) => ({
      title: tabTitle?.label?.text,
      links: children.map(({ label, target }) => ({
        label: label?.text,
        url: target,
      })),
    })),
  };
};

module.exports = jsonToProps;
