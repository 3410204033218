import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip/vis';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import QuestionsUnregistered from '../../../../components/questions/questions-unregistered';

const FEATURE_NAME = 'unregistered_questions';

const mapStateToProps = (
  {
    [FEATURE_NAME]: { id, form, messages, disabled, conditions, key, recaptchaPublicKey, isFetching, ...rest },
    baseUrl,
    component,
    id: itemId,
    recaptchaSiteKey,
  },
  ownProps,
) => ({
  id,
  form,
  messages,
  disabled,
  url: `${baseUrl}/${itemId}/questions`,
  itemId,
  conditions,
  component,
  recaptchaPublicKey,
  recaptchaSiteKey,
  key,
  isFetching,
  ...rest,
  className: ownProps.className,
});
const mapDispatchToProps = dispatch => ({
  onChange: ({ input, value }) => dispatch(actions.onChangeUnregisteredQuestionValue({ input, value })),
  onCreateQuestion: ({ action, itemId, form, outPutValues, track, hasErrors, recaptchaV2Token, recaptchaSiteKey }) =>
    dispatch(
      actions.onCreateQuestionsUnregistered({
        action,
        itemId,
        form,
        outPutValues,
        track,
        hasErrors,
        recaptchaV2Token,
        component: FEATURE_NAME,
        origin: 'vip',
        recaptchaSiteKey,
      }),
    ),
  onSetFormErrors: ({ form }) =>
    dispatch(actions.onSetQuestionsUnregisteredFormError({ form, component: FEATURE_NAME })),
  onEnableStorageData: ({ canEdit, isEditing }) =>
    dispatch(actions.onEnableStorageDataInQuestionsUnregisteredForm({ canEdit, isEditing, component: FEATURE_NAME })),
  onRequestFocus: ({ focus }) => dispatch(actions.onScrollComponent({ component: FEATURE_NAME, focus })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(QuestionsUnregistered);
