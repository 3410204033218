import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import jsonToProps from './json-to-props';
import SeoSmartTabs from '../../../../components/smart-tabs';

const FEATURE_NAME = 'smart_tabs';

const mapStateToProps = ({ components: { [FEATURE_NAME]: props } }) => jsonToProps(props);

const enhance = compose(connect(mapStateToProps));

export default enhance(SeoSmartTabs);
