/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { createPortal } from 'react-dom';
import { arrayOf, bool, func, number, string, shape, oneOfType } from 'prop-types';
import PhotoSwipe from 'frontend-photoswipe';
import PhotoSwipeUIDefault from 'frontend-photoswipe/dist/photoswipe-ui-default';
import usePortal from '../../hooks/use-portal';
import LightboxTemplate from './template';
import { PROVIDER } from '../figure/constants';
import { clipMultimediaString } from '../clip-multimedia';
import { constants } from '../../utils/constants';
import { CLICK_PROPS_TYPE, DEFAULT_SETTINGS } from './constant';
import GalleryPropsContext from '../gallery/context/gallery.contex';

const { DEVICE_TYPE } = constants;

// global window document
if (process.env.NODE_ENV === 'test') {
  DEFAULT_SETTINGS.scaleMode = 'orig';
}

const Lightbox = props => {
  const {
    deviceType,
    inline,
    onAfterChange,
    onBeforeChange,
    onBeforeOpen,
    onClose,
    onTouch,
    pictures,
    settings,
    shapes,
    videoConfig,
  } = props;

  const { position, setCurrentIndex } = useContext(GalleryPropsContext);

  const ref = useRef();
  const target = usePortal('ligthbox');
  const [pswp, setPswp] = useState(null);
  const firstTapRef = useRef(true);
  let indexValue = position?.current;

  useEffect(() => {
    const { thumbnailClassname, ...restSettings } = settings;

    let lightbox = null;

    const items = pictures.map(item => {
      if (item.provider === PROVIDER.CLIP) {
        const clipItemClassName = `clip-wrapper__${deviceType}-fullscreen`;

        return { html: clipMultimediaString(item, clipItemClassName) };
      }

      if (item.url) {
        return {
          html: `
          <iframe
            alt="${videoConfig.alt}"
            title="${videoConfig.title}"
            src="${item.url}"
            width="960"
            height="540"
            frameBorder="${videoConfig.frameBorder}"
            allow="${videoConfig.allow}
            allowFullscreen="1"
          />`,
          hasVideo: true,
        };
      }

      return {
        msrc: item.msrc,
        src: item.src2x || item.src,
        w: item.width,
        h: item.height,
      };
    });

    const disableDoubleTapZoom = {
      maxSpreadZoom: 1,
      /* istanbul ignore next */
      getDoubleTapZoom: (isMouseClick, item) => item.initialZoomLevel,
    };

    let currentSettings = {
      index: position?.current,
      getThumbBoundsFn: i => {
        const thumbnail = document.querySelector(`${thumbnailClassname}[data-index="${i}"]`);
        if (!thumbnail) {
          return null;
        }
        // get window scroll Y
        const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
        // optionally get horizontal scroll
        // get position of element relative to viewport
        const rect = thumbnail.getBoundingClientRect();
        // w = width
        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
      },
      history: deviceType !== DEVICE_TYPE.MOBILE,
      ...DEFAULT_SETTINGS,
      ...restSettings,
    };

    if (pictures[indexValue].provider === PROVIDER.CLIP) {
      currentSettings = {
        ...currentSettings,
        ...disableDoubleTapZoom,
      };
    }

    if (!pswp) {
      lightbox = new PhotoSwipe(ref.current, PhotoSwipeUIDefault, items, currentSettings);

      lightbox.listen('firstUpdate', () => onBeforeOpen());
      lightbox.listen('beforeChange', () => onBeforeChange(lightbox.getCurrentIndex()));
      lightbox.listen('afterChange', () => {
        let currentIndex = lightbox.getCurrentIndex();

        // Workaround that avoid the lightbox's bug
        // when the user open fullscreen first time and
        // the getCurrentIndex returns the last position before restart the page
        if (firstTapRef.current) {
          currentIndex = indexValue;
          firstTapRef.current = false;
        }

        setCurrentIndex(currentIndex);
        onAfterChange(currentIndex);
      });

      lightbox.listen('close', () => onClose(lightbox.getCurrentIndex()));
      /* istanbul ignore next */
      lightbox.listen('touchRelease', (pointerEvent, property) => {
        if (CLICK_PROPS_TYPE.includes(property?.type)) {
          onTouch(lightbox.getCurrentIndex(), pointerEvent?.target, { close: lightbox.close });
        }
      });
      lightbox.init();

      setPswp(lightbox);
    }
  }, [pictures]);

  useEffect(() => {
    const currentIndex = pswp?.getCurrentIndex();

    if (typeof currentIndex === 'number' && indexValue !== currentIndex) {
      pswp.goTo(indexValue);
    }
  }, [pswp, position?.current]);

  useEffect(() => {
    if (position?.current !== indexValue) {
      indexValue = position?.current;
    }
  }, [position]);

  const lightboxTemplateProps = {
    ref,
    shapes,
    index: indexValue,
  };

  return inline ? (
    <LightboxTemplate {...lightboxTemplateProps} className="inline" />
  ) : (
    createPortal(<LightboxTemplate {...lightboxTemplateProps} />, target)
  );
};

Lightbox.propTypes = {
  pictures: arrayOf(
    shape({
      id: string,
      src: string,
      src2x: string,
      url: string,
      thumbnail: oneOfType([string, shape()]),
    }),
  ),
  settings: shape({
    arrowEl: bool,
  }),
  shapes: arrayOf(string),
  videoConfig: shape({
    alt: string,
    title: string,
    allow: string,
    frameBorder: number,
    showSpinner: bool,
  }),
  onAfterChange: func,
  onBeforeChange: func,
  onBeforeOpen: func,
  onClose: func,
  onTouch: func,
  inline: bool,
  deviceType: string,
};

Lightbox.defaultProps = {
  pictures: [],
  onAfterChange: () => {},
  onBeforeChange: () => {},
  onBeforeOpen: () => {},
  onClose: () => {},
  onTouch: () => {},
  videoConfig: null,
  inline: false,
  settings: {
    arrowEl: true,
  },
  shapes: [],
  deviceType: DEVICE_TYPE.DESKTOP,
};

export default Lightbox;
