import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Card, CardContent } from '@andes/card';
import DynamicAccordionsLinks from '@seo-frontend-components/dynamic-accordions-links';

const namespace = 'ui-pdp-smart-tabs';

const SeoSmartTabs = props => {
  const { tabs = [] } = props;

  if (tabs.length === 0) {
    return null;
  }

  return (
    <Card className={`${namespace}__container`}>
      <CardContent>
        <DynamicAccordionsLinks {...props} />
      </CardContent>
    </Card>
  );
};

SeoSmartTabs.displayName = 'SeoSmartTabs';

SeoSmartTabs.defaultProps = {
  tabs: [],
};

SeoSmartTabs.propTypes = {
  tabs: arrayOf(
    shape({
      title: string.isRequired,
      links: arrayOf(
        shape({
          url: string.isRequired,
          label: string.isRequired,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
  title: string.isRequired,
  tracks: shape({
    melidata_track: shape({
      path: string,
      type: string,
      event_data: shape({}),
    }),
  }),
  trackEvent: func,
};

export default SeoSmartTabs;
